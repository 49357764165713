<template>
  <dx-util-popup
    ref="bulkReservationPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    width="95vw"
    height="95vh"
    :position="'center'"
    @shown="popupShown"
    @hidden="popupHidden"
  >
    <dx-util-scroll-view width="100%" height="95%">
      <div>
        <div class="d-flex justify-content-between">
          <div class="alert alert-warning">
            <p>
              The items listed below have missing reservation.
              Please match the correct items from your inventory.
            </p>
            <p>
              If you mark as shipped without reservations your inventory may not
              reflect the correct quantity of those products.
            </p>
          </div>
          <div v-if="orders" class="badge badge-light-success" style="height: 64px">
            <span class="">
              Order Count
            </span>
            <h3 class="pt-half">
              {{ orders.length }}
            </h3>
          </div>
        </div>
        <div>
          <div v-for="group in groupedItemsBySku" :key="group.sku" class="mb-3">
            <bulk-mark-as-shipped-group :group="group" />
          </div>
        </div>
      </div>
    </dx-util-scroll-view>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Mark As Shipped" type="success" style="width:240px" @click="handleSubmit" />
    </div>
    <div>
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>

  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import fbmService from '@/http/requests/fbm/fbmService'
import BulkMarkAsShippedGroup from '@/views/apps/outbound/fbm-shipping/components/BulkMarkAsShippedGroup.vue'

export default {
  components: {
    BulkMarkAsShippedGroup,
    'light-box': CoolLightBox,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    orders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popupTitle: '',
      images: [],
      index: null,
      fbmInventorySearchCompId: '',
      selectedProduct: {},
      searchData: {
        productName: '',
        storeId: null,
        fbmOrderItemId: null,
        orderSku: null,
        orderId: null,
        isAdditionalItem: false,
        orderQuantity: 0,
      },
      reservations: [],
    }
  },
  computed: {
    bulkReservationPopup() {
      return this.$refs.bulkReservationPopupRef.instance
    },
    groupedItemsBySku() {
      const groupedItems = {}

      this.orders.forEach(order => {
        order.items.forEach(item => {
          const { sku } = item
          if (!groupedItems[sku]) {
            groupedItems[sku] = {
              ...item,
              fmbOrderIds: [order.id],
              fbmOrderItemIds: [item.id],
              smallImage: '',
              largeImage: '',
              platform: order.platform,
              storeId: order.storeId,
            }
          } else {
            // If SKU already exists, just push the new fmbOrderId
            groupedItems[sku].fmbOrderIds.push(order.id)
            groupedItems[sku].fbmOrderItemIds.push(item.id)
            groupedItems[sku].requiredReserveCount += item.requiredReserveCount
            groupedItems[sku].reserveCount += item.reserveCount
            groupedItems[sku].quantity += item.quantity
          }
        })
      })
      // Convert groupedItems object to an array for sorting
      const groupedItemsArray = Object.values(groupedItems)

      // Sort the array by requiredReserveCount in ascending order
      groupedItemsArray.sort((a, b) => b.requiredReserveCount - a.requiredReserveCount)
      return groupedItemsArray
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.bulkReservationPopup.show()
      },
    },
  },
  methods: {
    popupShown() {
      this.popupTitle = 'Reservation / Product Matching For Mark As Shipped'
    },
    popupHidden() {
      // this.$emit('clear-orders')
    },
    closePopup() {
      this.bulkReservationPopup.hide()
    },
    async handleSubmit() {
      const orderIds = this.orders.map(item => item.id)
      await fbmService.markAsShippedInBulk(orderIds)
      this.$emit('emit-form-saved')
      this.closePopup()
    },
    enlargeImage(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) return
      this.images.length = 0
      this.index = 0
      this.images.push(imageUrl)
    },
    openFBMInventory(group) {
      this.searchData.orderSku = group.sku
      this.searchData.productName = group.firstItem.productTitle
      this.searchData.asin = group.firstItem.asin
      this.searchData.storeId = group.firstItem.storeId
      this.fbmInventorySearchCompId = this.$uid()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
