<template>
  <div>
    <div class="row align-items-start">
      <div class="col-6">
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div role="button" @click="enlargeImage(group.largeImage)">
              <img :src="group.smallImage || require('@/assets/images/undraw/no_images.svg')"
                   style="height: 80px; width: 80px; margin-right: 0.625rem; margin-top: 0.425rem;"
                   class="rounded text-white"
              >
            </div>
            <div class="pt-half">
              <div class="text-warning font-weight-bold">
                 <strong>
                   {{ group.productTitle }}
                 </strong>
              </div>
              <small class="d-block">
                SKU: {{ group.sku }}
              </small>
              <small v-if="group.platform === 'amazonsp'">
                ASIN: {{ group.productId }}
              </small>
            </div>
          </div>
          <div class="d-flex flex-column align-items-start" style="gap: 6px">
            <div class="d-flex" style="gap: 6px">
              <div class="badge badge-light-secondary">
                <div class="text-center">
                  <span>Quantity</span>
                  <h5>
                    {{ group.quantity }}
                  </h5>
                </div>
              </div>
              <div class="badge badge-light-secondary">
                <div class="text-center">
                  <span>Reserved</span>
                  <h5>
                    {{ group.reserveCount }}
                  </h5>
                </div>
              </div>
              <div class="badge" :class="group.requiredReserveCount > 0 ? 'badge-light-danger' : 'badge-light-success'">
                <div class="text-center">
                  <span>Required</span>
                  <h5>
                    {{ group.requiredReserveCount }}
                  </h5>
                </div>
              </div>
            </div>
            <dx-util-button class="w-100" :icon="group.requiredReserveCount > 0 ? 'icon bi-search' : ''" :text="group.requiredReserveCount > 0 ? 'Find Item': `Change Reservation`" :type="group.requiredReserveCount > 0 ? 'success': `default`" @click="openFBMInventory(group)" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div v-if="firstReservationItem" class="d-flex">
          <div role="button" @click="enlargeImage(firstReservationItem.largeImage)">
            <img :src="firstReservationItem.smallImage || require('@/assets/images/undraw/no_images.svg')"
                 style="height: 80px; width: 80px; margin-right: 0.625rem; margin-top: 0.425rem;"
                 class="rounded text-white"
            >
          </div>
          <div class="flex-grow-1">
            <div>{{ firstReservationItem.matchedProductTitle }}</div>
            <small class="d-block">
              SKU: {{ firstReservationItem.matchedProductMsku }}
            </small>
            <small>
              ASIN: {{ firstReservationItem.matchedProductAsin }}
            </small>
            <small>
              UPC: {{ firstReservationItem.matchedProductUpc }}
            </small>
          </div>
          <div class="d-flex flex-column align-items-start" style="gap: 6px">
            <div class="d-flex" style="gap: 6px">
              <div class="badge badge-light-secondary">
                <div class="text-center">
                  <span>Quantity</span>
                  <h5>
                    {{ newOrderQuantity }}
                  </h5>
                </div>
              </div>
              <div class="badge badge-light-secondary">
                <div class="text-center">
                  <span>Reserved</span>
                  <h5>
                    {{ newReserveCount }}
                  </h5>
                </div>
              </div>
              <div class="badge" :class="newRequiredReserveCount > 0 ? 'badge-light-danger' : 'badge-light-success'">
                <div class="text-center">
                  <span>Required</span>
                  <h5>
                    {{ newRequiredReserveCount }}
                  </h5>
                </div>
              </div>
            </div>
            <dx-util-button :disabled="newRequiredReserveCount > 0" class="w-100" text="Cancel Reservation" type="danger" @click="cancelReservation(group)" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <fbm-inventory-search
        :component-id="fbmInventorySearchCompId" :search-data="searchData"
        :is-mark-as-shipped="true"
        @emit-item-selected="updateItemSelection"
      />
    </div>
  </div>
</template>

<script>
import fbmService from '@/http/requests/fbm/fbmService'
import useBuyBulkLabels from '@/views/apps/outbound/fbm-shipping/components/useBuyBulkLabels'
import useConfirmation from '@/libs/app/confirmation'

export default {
  components: {
    'fbm-inventory-search': () => import('./FbmInventorySearch.vue'),
  },
  props: {
    group: {
      type: Object,
      default: () => {
      },
    },
  },
  setup() {
    const { orderIds } = useBuyBulkLabels()

    const { pshConfirm } = useConfirmation()
    return {
      orderIds,
      pshConfirm,
    }
  },
  data() {
    return {
      reservationSum: [],
      firstReservationItem: {},
      newRequiredReserveCount: 0,
      newReserveCount: 0,
      newOrderQuantity: 0,
      images: [],
      index: null,
      fbmInventorySearchCompId: '',
      selectedProduct: {},
      searchData: {
        productName: '',
        storeId: null,
        fbmOrderItemId: null,
        orderSku: null,
        orderId: null,
        isAdditionalItem: false,
        orderQuantity: 0,
      },
    }
  },
  watch: {
    group: {
      immediate: true,
      deep: true,
      async handler() {
        await this.checkReservation()
      },
    },
  },
  methods: {
    async checkReservation() {
      const reservationSum = await fbmService.checkItemsReservationData(this.group.fbmOrderItemIds)
      if (reservationSum && reservationSum.length > 0) {
        this.firstReservationItem = reservationSum[0]
        this.sumFields(reservationSum)
      }
    },
    sumFields(data) {
      this.newRequiredReserveCount = 0
      this.newReserveCount = 0
      this.newOrderQuantity = 0
      data.forEach(item => {
        this.newRequiredReserveCount += item.requiredReserveCount || 0
        this.newReserveCount += item.reserveCount || 0
        this.newOrderQuantity += item.orderQuantity || 0
      })
    },
    enlargeImage(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) return
      this.images.length = 0
      this.index = 0
      this.images.push(imageUrl)
    },
    openFBMInventory(group) {
      this.searchData.orderSku = group.sku
      this.searchData.productName = group.productTitle
      this.searchData.asin = group.asin
      this.searchData.storeId = group.storeId
      this.fbmInventorySearchCompId = this.$uid()
    },
    async updateItemSelection(product) {
      this.selectedProduct = product
      const reservations = []
      this.group.fbmOrderItemIds.forEach(id => {
        reservations.push({
          fbmOrderItemId: id,
          productId: this.selectedProduct.id,
        })
      })
      await fbmService.makeBulkReservation(reservations)
      await this.checkReservation()
    },
    async cancelReservation(group) {
      const confirm = await this.pshConfirm(
        'Cancel Reservation Confirmation',
        '<div style="padding-top: 10px;">Are you sure you want to cancel the reservation?</div>',
        'exclamation-circle-fill',
        'Delete reservation',
        'Cancel',
        'default',
      )
      if (confirm) {
        await fbmService.cancelBulkReservation(group.fbmOrderItemIds)
        await this.checkReservation()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
