/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Pager from '@core/dev-extreme/utils/pager'
import fbmService from '@/http/requests/fbm/fbmService'

const fbmForceStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    let sort = ''
    if (loadOptions.sort && loadOptions.sort.length > 0) {
      sort = loadOptions.sort
    } else {
      if (loadOptions.searchValue?.orderStatus === 'AWAITING') {
        sort = [{ selector: 'orderAt', desc: false }]
      }
      if (loadOptions.searchValue?.orderStatus === 'SHIPPED') {
        sort = [{ selector: 'orderAt', desc: true }]
      }
    }

    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    let sortOrder = ''
    if (sort) {
      pager.setSortQuery(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }

    const pageableQuery = `${pager.toCreatePageable()}${sortOrder}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    if (filters === null) return {}
    const response = await fbmService.getFbmOrders(filters, pageableQuery)
    return {
      data: response.data.body.content,
      totalCount: response.data.body.totalElements,
    }
  },
})
const fbmForceDataSource = new DataSource({ store: fbmForceStore })
export { fbmForceDataSource }
