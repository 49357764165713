import FbmOrderStatusEnum from '@/enums/fbmOrderStatusEnum'
import FbmResourceTypeEnum from '@/enums/fbmResourceTypeEnum'
import BaseSearchFilter from './BaseSearchFilter'

class FbmOrderSearchFilter extends BaseSearchFilter {
  /** @type {string} */
  #orderStatus = FbmOrderStatusEnum.ALL.key

  /** @type {string} */
  #fbmResourceType = FbmResourceTypeEnum.ALL.key

  /** @type {string} */
  #orderNumber = 0

  /** @type {string} */
  #storeName = 0

  constructor() {
    super()
    this.#orderStatus = this.orderStatus
    this.#fbmResourceType = this.fbmResourceType
    this.#storeName = this.storeName
  }

  setDefaultFilters() {
    this.accountNo = null
    this.companyId = 0
    this.storeId = 0
    this.storeName = ''
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.orderStatus = FbmOrderStatusEnum.AWAITING.key
    this.fbmResourceType = FbmResourceTypeEnum.ALL.key
    this.orderNumber = 0
  }

  /**
   * The complete UploadedBatchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} storeMaöe - Store Name.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {string} q - Query.
   * @property {string} orderStatus - Order Status.
   * @property {string} fbmResourceType - Rsource Type.
   * @property {string} orderNumber - Order Number.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      storeName: this.storeName,
      warehouseId: this.warehouseId,
      orderStatus: this.orderStatus,
      orderNumber: this.orderNumber,
      fbmResourceType: this.fbmResourceType,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
    }
    return filter
  }

  /** @returns {string} */
  get orderStatus() {
    return this.#orderStatus
  }

  /** @param {string} value */
  set orderStatus(value) {
    this.#orderStatus = value
  }

  /** @returns {string} */
  get fbmResourceType() {
    return this.#fbmResourceType
  }

  /** @param {string} value */
  set fbmResourceType(value) {
    this.#fbmResourceType = value
  }

  /** @returns {number} */
  get orderNumber() {
    return this.#orderNumber
  }

  /** @param {number} value */
  set orderNumber(value) {
    this.#orderNumber = value
  }
  /** @param {number} value */

  set storeName(value) {
    this.#storeName = value
  }
}

export default new FbmOrderSearchFilter()
