<template>
  <div class="container-fluid">
    <div id="fbm-force">
      <div class="row">
        <div id="left-column" class="">
          <stores-left-side-bar v-if="!isIndividualTenant" @emit-account-no="onFilterByAccountNo" @emit-refresh-stores="refreshStoresAndGrid" />
        </div>
        <div id="middle-column" class="rounded">
          <div class="card p-half rounded-0" style="height:calc(100vh - 140px);">
            <dx-data-grid
              id="shippingItemGrid"
              ref="shippingItemRef"
              :data-source="fbmForceDataSource"
              height="calc(100vh - 150px)"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :auto-navigate-to-focused-row="false"
              :focused-row-enabled="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
              :customize-columns="customizeColumns"
              @initialized="onInitialized"
              @row-click="openOrderDetail"
              @toolbar-preparing="onToolbarPreparing"
              @option-changed="onOptionChanged"
            >
              <!--region    ::DataGird base components -->
              <dx-sorting mode="single" />
              <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
              <dx-paging :page-size="pagerOptions.pageSize" />
              <dx-pager
                :allowed-page-sizes="pagerOptions.allowedPageSizes"
                :show-info="pagerOptions.showInfo"
                :show-navigation-buttons="pagerOptions.showNavigationButtons"
                :show-page-size-selector="pagerOptions.showPageSizeSelector"
                :visible="pagerOptions.visible"
              />
              <dx-selection
                show-check-boxes-mode="onClick"
                select-all-mode="page"
                :allow-select-all="true"
                :width="16"
                mode="multiple"
              />
              <!--endregion ::DataGird base components -->
              <dx-column data-field="storeName" caption="Store"
                         cell-template="accountCompanyStoreTemplate"
                         :allow-sorting="false"
              />
              <template #accountCompanyStoreTemplate="{data}">
                <div>
                  <div class="row">
                    <div class="col-2">
                      <b-img center height="24"
                             :src="require(`@/assets/images/svg/ecommerce/${data.data.platform.toLowerCase()}.svg`)"
                             :title="data.data.platform"
                      />
                    </div>
                    <div class="col-10">
                      {{ data.data.storeName }}
                      <div v-if="isServingTenant" class="">
                        <small>
                          {{ data.data.accountNo }} - {{ data.data.companyName }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <dx-column data-field="orderNumber" caption="Order #" width="280" cell-template="orderTemplate" />
              <template #orderTemplate="{data}">
                <table>
                  <tr>
                    <td>
                      <small>Order #:</small>
                    </td>
                    <td>
                      <div v-if="data.data.platform === 'amazonsp' || data.data.platform === 'AmazonSP'">
                        <a target="_blank" :href="`https://sellercentral.amazon.com/orders-v3/order/${data.value}`">
                          {{ data.value }}
                        </a>
                      </div>
                      <div v-else>
                        {{ data.value }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>SKU:</small>
                    </td>
                    <td>
                      <div class="d-flex flex-wrap">
                      <span v-for="sku in data.data.itemSkuArray" :key="sku"
                            class="rounded text-primary p-half sku"
                            @click="filterByItemSku(sku)"
                      >
                        {{ sku }} <p-icon name="bi-filter-circle" size="13px" />
                      </span>
                      </div>
                    </td>
                  </tr>
                </table>
              </template>
              <dx-column caption="Status" cell-template="statusTemplate" width="160" :allow-sorting="false" />
              <template #statusTemplate="{data}">
                <table>
                  <tr>
                    <td>
                      <small>Order:</small>
                    </td>
                    <td>
                    <span class="badge text-capitalize" :class="resolveOrderStatus(data.data.orderStatus)">
                      {{ data.data.orderStatus }}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted">
                      <small>Fullfilment:</small>
                    </td>
                    <td>
                    <span class="badge text-capitalize" :class="resolvefullfillmentStatus(data.data.fullFilmentStatus)">
                      {{ data.data.fullFilmentStatus }}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted">
                      <small>Financial:</small>
                    </td>
                    <td>
                    <span class="badge text-capitalize" :class="resolveFinancialStatus(data.data.financialStatus)">
                      {{ data.data.financialStatus }}
                    </span>
                    </td>
                  </tr>
                </table>
              </template>
              <dx-column data-field="shippingMethodName" caption="Ship Method"
                         width="120"
                         cell-template="shippingMethodTemplate"
              />
              <template #shippingMethodTemplate="{data}">
              <span v-if="!data.value.includes('Standard')" class="badge badge-light-warning">
                {{ data.value }}
              </span>
                <span v-else>
                {{ data.value }}
              </span>
              </template>
              <dx-column data-field="orderAt" cell-template="orderDateTemplate" width="80" />
              <template #orderDateTemplate="{data}">
              <span :class="orderDateAge(data.value).variant">
                {{ orderDateAge(data.value).value }}
              </span>
              </template>
              <dx-column data-field="itemCount" caption="Items"
                         width="120" alignment="left"
                         cell-template="itemCountTemplate"
              />
              <template #itemCountTemplate="{data}">
                <table>
                  <tr>
                    <td>
                      <small>Ordered:</small>
                    </td>
                    <td>
                    <span class="badge badge-light-primary px-1">
                      {{ data.value }}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <small>Reserved:</small>
                    </td>
                    <td>
                      <div :class="resolveReservedStatusColor(data.data.reservStatus)" class="px-1">
                        {{ data.data.reservedCount }}
                      </div>
                    </td>
                  </tr>
                </table>
              </template>
              <dx-column data-field="labelCount" caption="Labels" :width="60" cell-template="labelCountTemplate" />
              <template #labelCountTemplate="{data}">
                <div :class="data.value > 0 ? 'badge badge-light-success' : 'badge badge-light-secondary'">
                  {{ data.value }}
                </div>
              </template>
              <dx-column v-if="false" data-field="fbmResourceType" caption="Source" width="auto" :hiding-priority="1" />
              <template #pshProductMatchedCellTemplate="{ data }">
                <div v-if="data.data.skuCount === 1">
                  <p-icon v-if="data.value" name="bi-check-circle-fill" color="success" />
                  <p-icon v-else name="bi-x-circle-fill" color="danger" />
                </div>
              </template>
              <template #buttons="{ data }">
                <div>
                  <div v-for="item in data" :key="item.id" class="">
                    <dx-util-button :id="item.btnId" :text="item.name" :icon="item.icon" :disabled="item.disabled" :visible="item.visible" class="w-100 text-left" styling-mode="text" @click="setActionByItem(item)" />
                  </div>
                </div>
              </template>
              <template #toolbarFilters>
                <div class="d-flex flex-row align-items-center px-half">
                  <div v-if="isIndividualTenant" class="mr-half">
                    <dx-util-select-box
                      v-model="fbmSelectedStore"
                      :data-source="fbmStores"
                      display-expr="storeName"
                      value-expr="storeId"
                      @value-changed="onStoreChange"
                    />
                  </div>
                  <div class="mr-half">
                    <dx-util-select-box
                      v-model="filter.orderStatus"
                      :data-source="filterStatus"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="onStatusChange"
                    />
                  </div>
                  <div class="mr-half">
                    <dx-util-text-box
                      v-model="filter.q"
                      :show-clear-button="true"
                      placeholder="Search"
                      @key-down="onCodeChanged"
                      @value-changed="onCodeChanged"
                    />
                  </div>
                </div>
              </template>
              <!--endregion ::DataGrid toolbar -->
            </dx-data-grid>
          </div>
        </div>
        <div id="right-column" class="">
          <div class="card rounded-0" style="height:calc(100vh - 140px);">
            <div class="card-header border-bottom py-0 mx-0">
              <div class="card-title text-warning py-1 d-flex">
                <div>
                  <dx-util-button v-if="currentOrder.id" icon="refresh" @click="refreshOrder" />
                </div>
                <div class="px-1">
                  Order #:
                </div>
                <div v-if="orderSelected">
                  <div v-if="currentOrder.platform === 'AmazonSP'">
                    <a target="_blank" :href="`https://sellercentral.amazon.com/orders-v3/order/${currentOrder.id2}`">
                      {{ currentOrder.id2 }}
                    </a>
                  </div>
                  <div v-else>
                    {{ currentOrder.id2 }}
                  </div>
                </div>
                <div v-if="orderSelected && currentOrder.preventAutoUpdate" class="round bg-danger text-center ml-half"
                     style="width: 28px;"
                >
                  <p-icon id="orderType" name="bi-hand-index-thumb" size="16px" color="white" />
                </div>
                <dx-util-popover :width="300" :visible="false"
                                 target="#orderType" position="right"
                                 show-event="dxhoverstart"
                                 hide-event="dxhoverend"
                >
                  This order has been fulfilled on the e-commerce platform.
                  Please close it manually in order to trace the order in PSH.
                  There will be no further updates on the e-commerce platform for this order.
                </dx-util-popover>
              </div>
              <fbm-order-totals v-if="orderSelected" />
            </div>
            <div>
              <dx-util-scroll-view show-scrollbar="onHover" style="height:calc(100vh - 200px);">
                <div v-if="!orderSelected" class="card-body">
                  <div class="py-half">
                    <div class="alert alert-warning">
                      Please select an order from the table
                    </div>
                  </div>
                </div>
                <div v-else class="card-body px-1">
                  <div :key="rerenderKey" class="py-half">
                    <div class="px-0">
                      <div class="mb-half">
                        <fbm-customer-details />
                      </div>
                      <div v-if="currentOrder.commentStr && currentOrder.commentStr !== 'comment: '" class="mb-half">
                        <fbm-order-notes />
                      </div>
                      <div class="mb-half">
                        <fbm-order-items :hide-inventory-search="false" />
                      </div>
                      <div v-if="hasPermission && !isCurrentOrderShipped" class="mb-half">
                        <fbm-reserved-items />
                      </div>
                      <div v-if="hasPermission && showCustomsDeclaration">
                        <customs-items :reference-id="currentOrder.id" :reference-key="'FBM'"
                                       :is-shipped="isCurrentOrderShipped"
                        />
                      </div>
                      <div class="mb-half">
                        <fbm-shipping-label-list />
                      </div>
                      <div v-if="hasPermission" class="mb-half">
                        <fbm-charges />
                      </div>
                      <div v-if="hasPermission" class="mb-half">
                        <fbm-create-shipment />
                      </div>
                    </div>
                  </div>
                </div>
              </dx-util-scroll-view>
            </div>
          </div>
        </div>
      </div>
      <dx-util-popup
        ref="batchNamePopup"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="true"
        :show-title="true"
        :width="480"
        height="auto"
        title="Batch Name"
      >
        <div>
          <dx-util-text-box v-model="batchName">
            <dx-util-validator>
              <dx-util-required-rule message="Batch name is required" />
            </dx-util-validator>
          </dx-util-text-box>
          <div class="text-right mt-1">
            <dx-util-button class="mx-half" text="Cancel" type="danger" :width="120" @click="closePopup" />
            <dx-util-button text="Save" type="success" :width="120" @click="generatePickList" />
          </div>
        </div>
      </dx-util-popup>
    </div>
    <div>
      <bulk-mark-as-shipped :component-id="bulkMarkAsShippedCompId" :orders="ordersForBulkMarkAsShipped" />
    </div>
    <div>
      <fbm-inventory-search
        :component-id="fbmInventorySearchCompId" :search-data="searchData"
        :is-mark-as-shipped="true"
        @emit-item-selected="reserveInBulk"
      />
    </div>
  </div>
</template>

<script>
import FbmStoreFilter from '@/http/models/search-filters/FbmStoreFilter'
import FbmOrderStatusEnum, { getFBMOrderItemStatusEnumList } from '@/enums/fbmOrderStatusEnum'
import FbmOrderSearchFilter from '@/http/models/search-filters/FbmOrderSearchFilter'
import GridBase from '@core/dev-extreme/mixins/grid/base'
// import { v4 as uuidv4 } from 'uuid'
import fbmService from '@/http/requests/fbm/fbmService'
import useCurrentUser from '@/libs/app/current-user'
import Split from 'split.js' // Import the Split.js library
import moment from 'moment'
import { Notify } from '@/@robustshell/utils'
import useBuyBulkLabels from '@/views/apps/outbound/fbm-shipping/components/useBuyBulkLabels'
import usePackingSlipDownload from '@/views/apps/outbound/fbm-shipping/components/packing-slip-pdf'
import BulkMarkAsShipped from '@/views/apps/outbound/fbm-shipping/components/BulkMarkAsShipped.vue'
import { fbmForceDataSource } from './components/fbmForceDataSource'
import useFbmState from './useFbmStates'
import useCustomsItems from '../../tools/components/useCustomsItems'
import useConfirmation from '@/libs/app/confirmation'
// import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BulkMarkAsShipped,
    'stores-left-side-bar': () => import('./components/StoresLeftSideBar.vue'),
    'fbm-customer-details': () => import('./components/FbmCustomerDetails.vue'),
    'fbm-order-notes': () => import('./components/FbmOrderNotes.vue'),
    'fbm-order-items': () => import('./components/FbmOrderItems.vue'),
    'fbm-shipping-label-list': () => import('./components/FbmShippingLabelList.vue'),
    'fbm-order-totals': () => import('./components/FbmOrderTotals.vue'),
    'fbm-reserved-items': () => import('./components/FbmReservedItems.vue'),
    'customs-items': () => import('@/views/apps/tools/components/CustomsItems.vue'),
    'fbm-charges': () => import('./components/FbmCharges.vue'),
    'fbm-create-shipment': () => import('./components/FbmCreateShipment.vue'),
    'fbm-inventory-search': () => import('./components/FbmInventorySearch.vue'),
  },
  mixins: [GridBase],
  setup() {
    const {
      fbmOrderId,
      orderSelected,
      currentOrder,
      fbmOrderReloadNeeded,
      fbmSelectedStore,
      fbmStores,
      isCurrentOrderShipped,
      getFbmOrder,
      getFbmOrderItems,
      getShippingLabels,
      getReservedItems,
      shippingLabelsArray,
      showCustomsDeclaration,
      selectedRows,
      loadFbmStores,
    } = useFbmState()
    const { setStoreId } = useCustomsItems()
    const { pshConfirm } = useConfirmation()

    const {
      isServingTenant,
      isIndividualTenant,
      userFullname,
      userTenantId,
    } = useCurrentUser()

    const {
      setOrderIds,
      // setOrdersForBuyBulkLabel,
    } = useBuyBulkLabels()

    const { downloadPackingSlips } = usePackingSlipDownload()
    return {
      fbmOrderId,
      orderSelected,
      isServingTenant,
      currentOrder,
      fbmOrderReloadNeeded,
      fbmSelectedStore,
      fbmStores,
      isIndividualTenant,
      isCurrentOrderShipped,
      selectedRows,
      userFullname,
      userTenantId,
      shippingLabelsArray,
      getFbmOrder,
      getFbmOrderItems,
      getShippingLabels,
      getReservedItems,
      setStoreId,
      showCustomsDeclaration,
      // setOrdersForBuyBulkLabel,
      setOrderIds,
      downloadPackingSlips,
      pshConfirm,
      loadFbmStores,
    }
  },
  data() {
    return {
      bulkMarkAsShippedCompId: '',
      fbmInventorySearchCompId: '',
      searchData: {},
      fbmShippingLabelDataSource: [],
      fbmForceDataSource,
      inventorySearchComponentId: '',
      shippingLabelComponentId: '',
      customerShippingLabelComponentId: '',
      createShipmentComponentId: '',
      customerLabelType: '',
      selectedOrder: {},
      selectedItem: {},
      searchText: '',
      reservedItems: [],
      orderShippingAddress: {},
      orderItems: {},
      orderCustomer: {},
      fieldTemplate: 'field',
      stores: [],
      isStoreDataLoading: true,
      isAdditionalItem: false,
      filterStatus: getFBMOrderItemStatusEnumList(),
      filter: {
        orderStatus: this.$route.query.status ?? FbmOrderStatusEnum.AWAITING.key,
        orderNumber: '',
        accountNo: null,
        storeId: this.$route.query.storeId ?? 0,
        q: '',
      },
      isCurrentStoreManual: false,
      selectedFbmOrderId: 0,
      initialLoad: true,
      rerenderKey: '',
      batchName: '',
      ordersForBulkMarkAsShipped: [],
      preventRowClick: false,
    }
  },
  computed: {
    sortedStores() {
      return [...this.stores] // .sort((a, b) => b.itemCount - a.itemCount)
    },
    storeCount() {
      return this.sortedStores.length
    },
    isShipped() {
      return this.filter?.orderStatus === 'SHIPPED'
    },
    dataGrid() {
      return this.$refs.shippingItemRef.instance
    },
    dataSource() {
      return this.dataGrid.getDataSource()
    },
    hasAdminPermission() {
      return this.$can('read', 'resource_private_store_management')
    },
    hasAdminRights() {
      return this.$can('read', 'resource_admin')
    },
    hasTestPermission() {
      return this.$can('read', 'resource_admin') || this.$can('read', 'resource_test')
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no') || this.isIndividualTenant
    },
    currentRouteQuery() {
      return { ...this.$route.query }
    },
    storeIdParam() {
      return this.$route.query.storeId
    },
  },
  watch: {
    fbmOrderReloadNeeded: {
      immediate: true,
      deep: true,
      handler() {
        this.searchByFilter()
      },
    },
    storeIdParam: {
      immediate: true,
      handler() {
        this.filter.storeId = this.storeIdPAram ?? 0
        this.fbmSelectedStore = this.storeIdParam ?? 0
        this.dataGrid.clearSelection()
        this.checkManualStore(this.fbmSelectedStore)
      },
    },
  },
  created() {
    const status = this.$route.query.status ?? 'AWAITING'
    this.filter.orderStatus = status
    // this.filter.storeId = this.storeIdParam ?? 0
    // this.fbmSelectedStore = this.storeIdParam ?? 0
  },
  mounted() {
    if (this.isIndividualTenant) {
      this.loadFbmStores()
      this.fbmSelectedStore = 0
    }
    this.orderSelected = false
    let sizes = localStorage.getItem('split-sizes')
    if (sizes) {
      sizes = JSON.parse(sizes)
    } else {
      sizes = this.isIndividualTenant ? [0, 50, 50] : [15, 45, 40] // in percentage
    }

    Split(['#left-column', '#middle-column', '#right-column'], {
      sizes: sizes,
      minSize: this.isIndividualTenant ? [0, 400, 400] : [100, 100, 100],
      gutterSize: 6,
      direction: 'horizontal',
      cursor: 'col-resize',
      onDragEnd: sizesParam => {
        sizes = sizesParam
        localStorage.setItem('split-sizes', JSON.stringify(sizes))
      },
    })
  },
  methods: {
    checkManualStore(storeId) {
      // eslint-disable-next-line eqeqeq
      this.isCurrentStoreManual = this.fbmStores.filter(el => el.storeId == storeId)[0].isManualImport
      this.dataGrid.repaint()
    },
    onStoreChange(e) {
      this.orderSelected = false
      this.fbmSelectedStore = e.value
      // this.$router.replace({ query: { ...this.currentRouteQuery, storeId: this.fbmSelectedStore } }).catch(() => {})
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.filter.accountNo = e.event.target.value
          this.searchStoresByFilter()
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.filter.accountNo = ''
        this.fbmSelectedStore = 0
        this.searchStoresByFilter()
        this.searchByFilter()
      }
    },
    searchByFilter() {
      FbmOrderSearchFilter.setDefaultFilters()
      FbmOrderSearchFilter.storeId = this.fbmSelectedStore ?? 0
      FbmOrderSearchFilter.orderStatus = this.$route.query.status ?? 'AWAITING'
      FbmOrderSearchFilter.orderNumber = this.filter.orderNumber
      FbmOrderSearchFilter.accountNo = this.filter.accountNo || null

      FbmOrderSearchFilter.q = this.filter.q
      const filters = FbmOrderSearchFilter.getFilters()
      this.$nextTick(() => {
        this.dataSource.searchValue(filters)
        this.dataSource.reload()
      })
    },
    async searchStoresByFilter() {
      FbmStoreFilter.setDefaultFilters()
      const filters = FbmStoreFilter.getFilters()
      filters.accountNo = this.filter.accountNo
      filters.orderStatus = this.filter?.orderStatus
      filters.orderNumber = this.filter.orderNumber
      filters.q = this.filter.q
      const result = await fbmService.getFbmStoreSummary(filters, '')
      const all = {
        storeId: 0,
        storeName: 'All (Stores)',
        companyName: '',
        platform: 'all',
        orderCount: result.orderTotalCount,
      }
      this.stores = [all, ...result.stores]
      this.fbmStores = this.stores
      this.fbmSelectedStore = this.stores[0]?.storeId
      if (this.storeIdParam) {
        this.fbmSelectedStore = this.storeIdParam ?? 0
        this.filter.storeId = this.storeIdParam ?? 0
      }
    },
    refreshOrder() {
      this.getFbmOrder(this.currentOrder.id)
      this.getFbmOrderItems(this.currentOrder.id)
      this.getReservedItems(this.currentOrder.id)
      this.getShippingLabels(this.currentOrder.id)
    },
    openOrderDetail(e) {
      if (this.preventRowClick) {
        return
      }
      this.fbmOrderId = e.data.id
      this.currentOrder.id = e.data.id
      // this.currentOrder.international = e.data.international
      this.showCustomsDeclaration = !!e.data.isInternational
      // Value object used for compatibility onChanged event
      this.setStoreId({ value: e.data.storeId })
      // this.getFbmOrder(this.fbmOrderId)
      // this.getFbmOrderItems(this.fbmOrderId)
      // this.getShippingLabels(this.fbmOrderId)
      // this.getReservedItems(this.fbmOrderId)
      this.orderSelected = true
      this.shippingLabelsArray = []
      // this.rerenderKey = uuidv4()
      this.currentOrder.companyName = e.data.companyName
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        this.dataSource.pageIndex(0)
        this.dataSource.reload()
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Admin',
          items: [
            {
              id: 'refresh',
              name: 'Refresh',
            },
            {
              id: 'download',
              name: 'Download',
            },
            {
              id: 'pull-store-orders',
              name: 'Pull store orders',
            },
            {
              id: 'pull-order',
              name: 'Pull the order',
            },
            {
              id: 'match-awaiting-items',
              name: 'Match Items',
            },
            {
              id: 'reserve-awaiting-items',
              name: 'Reserve Items',
            },
          ],
          stylingMode: 'contained',
          visible: this.hasAdminPermission,
          width: 140,
          hint: 'Maintenance For Admin',
          onItemClick: async arg => {
            const { id } = arg.itemData
            const rows = this.dataGrid.getSelectedRowKeys()

            switch (id) {
              case 'refresh':
              case 'download':
                if (rows?.length === 0) {
                  Notify.error('No orders selected!')
                  return
                }
                if (id === 'download') {
                  const result = await fbmService.getWebOrders(rows)
                  if (result) {
                    const jsonString = JSON.stringify(result, null, 2)
                    const blob = new Blob([jsonString], { type: 'application/json' })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'response_data.json'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                  }
                } else { // id === 'refresh'
                  await fbmService.refreshOrders(rows)
                }
                break
              case 'pull-store-orders':
                if (this.fbmSelectedStore !== '0' && this.fbmSelectedStore !== 0 && this.fbmSelectedStore !== null && this.fbmSelectedStore !== undefined) {
                  await fbmService.pullStoreOrders(this.fbmSelectedStore)
                  this.searchByFilter()
                } else {
                  Notify.warning('Select a store before pulling the order from ecommerce platform')
                }
                break
              case 'pull-order':
                if (this.fbmSelectedStore !== '0' && this.fbmSelectedStore !== 0 && this.fbmSelectedStore !== null && this.fbmSelectedStore !== undefined) {
                  await fbmService.pullOrder(this.fbmSelectedStore, this.filter.q)
                  this.searchByFilter()
                } else {
                  Notify.warning('Select a store before pulling the order from ecommerce platform')
                }
                break
              case 'match-awaiting-items':
                if (this.fbmSelectedStore !== '0' && this.fbmSelectedStore !== 0 && this.fbmSelectedStore !== null && this.fbmSelectedStore !== undefined) {
                  await fbmService.matchAwaitingItems(this.fbmSelectedStore)
                  this.searchByFilter()
                } else {
                  Notify.warning('Select a store before matching items')
                }
                break
              case 'reserve-awaiting-items':
                if (this.fbmSelectedStore !== '0' && this.fbmSelectedStore !== 0 && this.fbmSelectedStore !== null && this.fbmSelectedStore !== undefined) {
                  await fbmService.reserveAwaitingItems(this.fbmSelectedStore)
                  this.searchByFilter()
                } else {
                  Notify.warning('Select a store before reserve awaiting items.')
                }
                break
              default:
                break
            }
          },
        },
        location: 'after',
      })

      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          id: 'editActions',
          text: 'Actions',
          icon: 'menu',
          width: 120,
          items: [
            {
              id: 'print-all-labels',
              btnId: 'btn-print-all-labels',
              name: 'Print Selected Labels',
              icon: 'icon bi-printer-fill',
              visible: this.hasTestPermission,
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'buy-bulk',
              btnId: 'btn-buy-bulk',
              name: 'Buy Shipping in Bulk',
              icon: 'icon bi-tags-fill',
              visible: this.hasTestPermission,
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'mark-as-shipped-bulk',
              btnId: 'btn-mark-as-shipped-bulk',
              name: 'Mark As Shipped',
              icon: 'icon bi-check2-square',
              visible: this.hasTestPermission,
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'reserve-bulk',
              btnId: 'btn-reserve-bulk',
              name: 'Reserve All',
              icon: 'icon bi-check-square',
              visible: this.hasTestPermission,
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'generate',
              btnId: 'btn-generate',
              name: 'Create Pick List',
              visible: this.hasPermission,
              icon: 'icon bi-card-checklist',
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'view',
              btnId: 'btn-view',
              name: 'View Pick Lists',
              visible: this.hasPermission,
              icon: 'icon bi-list-ol',
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'slips',
              btnId: 'slips-btn',
              name: 'Print Packing Slips',
              visible: this.hasPermission,
              icon: 'print',
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'upload',
              btnId: 'btn-upload',
              name: 'Upload Orders',
              icon: 'icon bi-cloud-arrow-up',
              // visible: this.hasAdminPermission,
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
            {
              id: 'delete',
              btnId: 'btn-delete',
              name: 'Delete Orders',
              icon: 'trash',
              visible: this.hasPermission,
              class: 'rounded-0 p-0 text-left',
              elementAttr: {
                class: 'p-0',
              },
            },
          ],
          type: 'default',
          stylingMode: 'contained',
          hint: 'Actions for multiple orders',
          dropDownOptions: { width: 'auto' },
          dropDownContentTemplate: 'buttons',
        },
        location: 'after',
        // visible: this.hasPermission,
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.dataSource.reload()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        visible: false,
        widget: 'dxButton',
        cssClass: 'mr-half',
        options: {
          icon: 'icon bi bi-sliders',
          onClick: () => {
          },
        },
        location: 'after',
      })
    },
    async setActionByItem(item) {
      switch (item.id) {
        case 'print-all-labels':
          await this.printAllLabels()
          break
        case 'buy-bulk':
          this.goToBuyBulkShippingLabel()
          break
        case 'mark-as-shipped-bulk':
          this.markAsShippedInBulk()
          break
        case 'reserve-bulk':
          this.openFbmInventory()
          break
        case 'generate':
          this.$refs.batchNamePopup.instance.show()
          this.batchName = this.generateBatchName()
          break
        case 'view':
          this.$router.push({ name: 'fbm-pick-list' })
          break
        case 'slips':
          await this.generateSlips()
          break
        case 'upload':
          this.$router.push({ name: 'orders-file-upload' })
          break
        case 'delete':
          await this.deleteOrders()
          break
        default:

          break
      }
    },
    async printAllLabels() {
      const selectedOrders = this.dataGrid.getSelectedRowsData()
      if (selectedOrders.length === 0) {
        Notify.danger('Please select at least one order.')
        return
      }
      const orderIds = selectedOrders.map(el => el.id)
      const response = await fbmService.downloadLabelsInBulk(orderIds)
      const contentType = response.headers['content-type']
      const contentDisposition = response.headers['content-disposition']
      const fileParts = contentDisposition.split('filename=')[1].replaceAll('"', '').split('.')
      const fileExtension = fileParts[1]
      const fileBlob = new Blob([response.data], { type: contentType })
      const anchor = document.createElement('a')
      anchor.href = window.URL.createObjectURL(fileBlob)
      anchor.download = `Merged_Labels.${fileExtension}`
      anchor.click()
    },
    async generateSlips() {
      const rows = this.dataGrid.getSelectedRowKeys()
      if (rows.length === 0) {
        Notify.danger('Please select at least one order.')
        return
      }
      const result = await fbmService.getOrderAllInfo(rows)
      await this.downloadPackingSlips(result)
    },
    generateBatchName() {
      const now = new Date()
      const formattedDate = moment(now)
        .format('YYYY.MM.DD-HH.mm.ss')
      const userName = this.userFullname.split(' ')[0].charAt(0) + this.userFullname.split(' ')[1].charAt(0)
      const tenantId = this.userTenantId.substring(0, 5)
      const batchName = `${tenantId}-${userName}-${formattedDate}`
      return batchName
    },
    async generatePickList() {
      const rows = this.dataGrid.getSelectedRowsData()
      this.selectedRows = rows.map(el => el.id)
      const payload = {
        pickBatchName: this.batchName,
        fbmOrderIdList: this.selectedRows,
      }
      await fbmService.createPickBatch(payload)
        .then(() => {
          this.$router.push({ name: 'fbm-pick-list' })
          this.selectedRows = []
          this.closePopup()
        })
    },
    async deleteOrders() {
      const rows = this.dataGrid.getSelectedRowKeys()
      if (rows.length === 0) {
        Notify.danger('Please select at least one order to delete.')
        return
      }
      const confirm = await this.pshConfirm(
        'Delete Order Confirmation',
        `Do you want to delete these orders? <br/> You can only delete manually added orders. Order Count:  <span style="color: orange;">${rows.length}</span>`,
        'exclamation-circle-fill',
        'Delete',
        'Cancel',
        'default',
      )
      if (confirm) {
        await fbmService.deleteManualOrders(rows)
        this.refreshStoresAndGrid()
      }
    },
    goToBuyBulkShippingLabel() {
      const selectedOrders = this.dataGrid.getSelectedRowsData()
      if (selectedOrders.length === 0) {
        Notify.warning('Please select at least one order to delete.')
        return
      }
      this.checkQuantitiesAndSkusForBulkLabels(selectedOrders)
    },
    markAsShippedInBulk() {
      const selectedOrders = this.dataGrid.getSelectedRowsData()
      if (selectedOrders.length === 0) {
        Notify.warning('Please select at least one order to mark as shipped.')
        return
      }
      const storeIds = selectedOrders.map(order => order.storeId)
      const uniqueStoreIds = new Set(storeIds)

      if (uniqueStoreIds.size > 1) {
        Notify.warning('Selected orders must all belong to the same store.')
        return
      }
      this.ordersForBulkMarkAsShipped = []
      this.ordersForBulkMarkAsShipped = selectedOrders
      this.bulkMarkAsShippedCompId = this.$uid()
    },
    openFbmInventory() {
      const selectedOrders = this.dataGrid.getSelectedRowsData()
      if (this.filter.q === '') {
        Notify.warning('Please click sku filter first.')
        return
      }
      if (selectedOrders.length === 0) {
        Notify.warning('Please select at least one order to reserve.')
        return
      }
      const filteredProducts = selectedOrders.flatMap(order => order.items
        .filter(item => item.sku === this.filter.q))
      this.searchData.orderSku = filteredProducts[0].sku
      this.searchData.productName = filteredProducts[0].productTitle
      this.searchData.asin = filteredProducts[0].asin
      this.searchData.storeId = selectedOrders[0].storeId
      this.fbmInventorySearchCompId = this.$uid()
    },
    async reserveInBulk(selectedProduct) {
      const selectedOrders = this.dataGrid.getSelectedRowsData()
      const fbmOrderItemIdList = this.getItemIdsBySku(selectedOrders, this.filter.q)
      const reservations = []
      fbmOrderItemIdList.forEach(id => {
        reservations.push({
          fbmOrderItemId: id,
          productId: selectedProduct.id,
        })
      })
      await fbmService.makeBulkReservation(reservations)
      this.searchByFilter()
    },
    getItemIdsBySku(data, targetSku) {
      return data.flatMap(order => order.items
        .filter(item => item.sku === targetSku)
        .map(item => item.id))
    },
    async checkQuantitiesAndSkusForBulkLabels(orders) {
      const firstOrder = orders[0]
      if (firstOrder.itemSkuArray.length > 1) {
        const confirm = await this.pshConfirm(
          'Multiple SKUs Detected',
          'The first order contains multiple SKUs. <br/> Please ensure that it has only one SKU for bulk shipping.',
          'exclamation-circle-fill',
          'OK',
          'Cancel',
          'default',
        )
        if (confirm) {
          // await fbmService.deleteManualOrders(rows)
          this.refreshStoresAndGrid()
        }
        return
      }
      const mismatchedOrders = []
      orders.slice(1)
        .forEach(order => {
          const sameQuantities = firstOrder.itemCount === order.itemCount && firstOrder.skuCount === order.skuCount
          const sameSkus = firstOrder.itemSkuArray.length === order.itemSkuArray.length
            && firstOrder.itemSkuArray.every(sku => order.itemSkuArray.includes(sku))
          const sameStoreName = firstOrder.storeName === order.storeName
          const sameIsInternational = firstOrder.isInternational === order.isInternational
          const hasLabelCount = order.labelCount > 0

          if (!sameQuantities || !sameSkus || !sameStoreName || !sameIsInternational || hasLabelCount) {
            mismatchedOrders.push(order)
          }
        })
      const mismatchedOrderIds = mismatchedOrders.map(el => el.orderNumber)
      if (mismatchedOrders.length > 0) {
        const confirm = await this.pshConfirm(
          'Incorrect Selections',
          `The selected orders must have the same quantities, SKU, and store as the initial selected order. <br /> Mismatched Order Count: <span style="color: orange;">${mismatchedOrderIds.length}</span>`,
          'exclamation-circle-fill',
          'Dismiss them and continue',
          'Cancel',
          'default',
        )
        if (confirm) {
          const keys = mismatchedOrders.map(el => el.id)
          this.dataGrid.deselectRows(keys)
          this.setOrderIds(this.dataGrid.getSelectedRowKeys(), orders[0].companyId, orders[0].itemSkuArray[0])
          await this.$router.push({ name: 'buy-bulk-shipping' })
        }
      } else {
        this.setOrderIds(this.dataGrid.getSelectedRowKeys(), orders[0].companyId, orders[0].itemSkuArray[0])
        await this.$router.push({ name: 'buy-bulk-shipping' })
      }
    },
    closePopup() {
      this.$refs.batchNamePopup.instance.hide()
      this.batchName = ''
    },
    resolveReservedStatusColor(status) {
      if (status === 0) return 'badge badge-light-danger'
      if (status === 1) return 'badge badge-light-warning'
      if (status === 2) return 'badge badge-light-success'
      return 'primary'
    },
    orderDateAge(val) {
      const now = new Date()
      const orderDate = new Date(val)
      const diff = now - orderDate
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
      let variant = ''
      if (days < 3 && days >= 0) variant = 'text-success'
      if (days >= 3 && days < 7) variant = 'text-warning'
      if (days >= 7) variant = 'text-danger'
      return {
        value: `${days}d ${hours}h`,
        variant: variant,
      }
    },
    onCodeChanged(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.filter.q = e.event.target.value
          this.searchByFilter()
          // this.searchStoresByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.filter.q = ''
        this.fbmSelectedStore = 0
        this.searchByFilter()
        // this.searchStoresByFilter()
      }
    },
    async onStatusChange(e) {
      this.isStoreDataLoading = true
      this.showCustomsDeclaration = false
      this.currentOrder.id = null
      const filters = FbmStoreFilter.getFilters()
      filters.orderStatus = e.value
      const result = await fbmService.getFbmStoreSummary(filters, '')
      const all = {
        id: 0,
        storeName: 'All (Stores)',
        companyName: '',
        platform: 'all',
        orderCount: result.orderTotalCount,
      }
      this.stores = [all, ...result.stores]
      this.fbmStores = this.stores
      this.fbmSelectedStore = this.$route.query.storeId
      this.isStoreDataLoading = false
      this.$router.push({
        query: {
          ...this.currentRouteQuery,
          status: e.value,
        },
      })
        .catch(() => {
        })
      this.orderSelected = false
      this.filter.orderStatus = e.value
      this.dataGrid.clearSorting()
      this.dataGrid.clearSelection()
      this.searchByFilter()
    },
    resolveOrderStatus(status) {
      if (status === 'Open') return 'badge-light-danger'
      if (status === 'Closed') return 'badge-light-success'
      return 'badge-light-primary'
    },
    resolvefullfillmentStatus(status) {
      if (status === 'Unshipped') return 'badge-light-danger'
      if (status === 'Partially Shipped') return 'badge-light-primary'
      if (status === 'Shipped') return 'badge-light-success'
      return 'badge-light-warning'
    },
    resolveFinancialStatus(status) {
      if (status === 'paid') return 'badge-light-success'
      return 'badge-light-primary'
    },
    filterByItemSku(sku) {
      this.preventRowClick = true
      this.filter.q = sku
      this.searchByFilter()
      setTimeout(() => {
        this.preventRowClick = false
      }, 500)
    },
    refreshStoresAndGrid() {
      this.searchStoresByFilter()
      this.searchByFilter()
    },
  }
  ,
}
</script>

<style lang="scss">
#fbm-force {
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) > td:not(.dx-focused) {
    cursor: pointer;
  }
}

.dark-layout #fbm-force {
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td {
    background-color: rgba(24, 217, 53, 0.289);
    color: white;
  }
}

.light-layout #fbm-force {
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td {
    background-color: rgba(24, 217, 53, 0.289);
    color: rgb(3, 52, 12) !important;
  }
}

.bordered-layout #fbm-force {
  .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td {
    background-color: rgba(24, 217, 53, 0.289);
    color: rgb(3, 52, 12) !important;
  }
}

.border-light {
  color: rgb(8, 188, 254);
}

.resizable {
  resize: horizontal;
  overflow: hidden;
}

.sku {
  color: rgb(161, 203, 218);
  font-size: 10px;
  margin: 3px;
  border: 0.1px solid rgb(92, 127, 139);
}

#btn-buy-bulk {
  .dx-icon {
    font-size: 1.3rem;
    color: rgba(168, 21, 225, 0.45);
    margin-right: 0.5rem;
  }
}

#btn-delete {
  .dx-icon {
    font-size: 1.3rem;
    color: #d75645;
    margin-right: 0.5rem;
  }
}

#btn-view {
  .dx-icon {
    font-size: 1.3rem;
    color: rgb(162, 115, 176);
    margin-right: 0.5rem;
  }
}

#btn-generate {
  .dx-icon {
    font-size: 1.3rem;
    color: #417fb4;
    margin-right: 0.5rem;
  }

}

#btn-upload {
  .dx-icon {
    font-size: 1.3rem;
    color: #55ab68;
    margin-right: 0.5rem;
  }
}

#btn-mark-as-shipped-bulk {
  .dx-icon {
    font-size: 1.3rem;
    color: #298c3f;
    margin-right: 0.5rem;
  }
}

#btn-print-all-labels {
  .dx-icon {
    font-size: 1.3rem;
    color: #f6ce52;
    margin-right: 0.5rem;
  }
}

#btn-reserve-bulk {
  .dx-icon {
    font-size: 1.3rem;
    color: #af6d78;
    margin-right: 0.5rem;
  }
}

.gutter {
  background-color: rgba(25, 32, 73, 0.2);
  cursor: col-resize;
}

.gutter.gutter-horizontal {
  width: 6px;
}

.gutter.gutter-vertical {
  height: 6px;
}

.gutter-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gutter-icon i {
  font-size: 16px;
}
</style>
